/* eslint-disable */
import { saveAs } from 'file-saver'
// import XLSX from 'xlsx'
import XLSX from 'yxg-xlsx-style'

// 设置表格中cell默认的字体，居中，颜色等
const defaultCellStyle = {
  font: {
    name: "宋体", sz: 11, color: { auto: 1 },
  },
  border: {
    color: { auto: 1 },
    top: { style: 'thin' },
    bottom: { style: 'thin' },
    left: { style: 'thin' },
    right: { style: 'thin' }
  },
  alignment: {
    /// 自动换行
    wrapText: 1,
    // 居中
    horizontal: "center",
    vertical: "center",
    indent: 0
  }
};

function generateArray(table) {
  var out = [];
  var rows = table.querySelectorAll('tr');
  var ranges = [];
  for (var R = 0; R < rows.length; ++R) {
    var outRow = [];
    var row = rows[R];
    var columns = row.querySelectorAll('td');
    for (var C = 0; C < columns.length; ++C) {
      var cell = columns[C];
      var colspan = cell.getAttribute('colspan');
      var rowspan = cell.getAttribute('rowspan');
      var cellValue = cell.innerText;
      if (cellValue !== "" && cellValue == +cellValue) cellValue = +cellValue;

      //Skip ranges
      ranges.forEach(function (range) {
        if (R >= range.s.r && R <= range.e.r && outRow.length >= range.s.c && outRow.length <= range.e.c) {
          for (var i = 0; i <= range.e.c - range.s.c; ++i) outRow.push(null);
        }
      });

      //Handle Row Span
      if (rowspan || colspan) {
        rowspan = rowspan || 1;
        colspan = colspan || 1;
        ranges.push({
          s: {
            r: R,
            c: outRow.length
          },
          e: {
            r: R + rowspan - 1,
            c: outRow.length + colspan - 1
          }
        });
      };

      //Handle Value
      outRow.push(cellValue !== "" ? cellValue : null);

      //Handle Colspan
      if (colspan)
        for (var k = 0; k < colspan - 1; ++k) outRow.push(null);
    }
    out.push(outRow);
  }
  return [out, ranges];
};

function datenum(v, date1904) {
  if (date1904) v += 1462;
  var epoch = Date.parse(v);
  return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
}

// 从json转化为sheet，xslx中没有aoaToSheet的方法，该方法摘自官方test
function sheet_from_array_of_arrays(data) {
  const ws = {};
  const range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } };
  for (let R = 0; R !== data.length; ++R) {
    for (let C = 0; C !== data[R].length; ++C) {
      if (range.s.r > R) range.s.r = R;
      if (range.s.c > C) range.s.c = C;
      if (range.e.r < R) range.e.r = R;
      if (range.e.c < C) range.e.c = C;
      /// 这里生成cell的时候，使用上面定义的默认样式
      const cell = { v: data[R][C], s: defaultCellStyle };
      if (cell.v == null) continue;
      const cell_ref = XLSX.utils.encode_cell({ c: C, r: R });

      /* TEST: proper cell types and value handling */
      if (typeof cell.v === 'number') cell.t = 'n';
      else if (typeof cell.v === 'boolean') cell.t = 'b';
      else if (cell.v instanceof Date) {
        cell.t = 'n'; cell.z = XLSX.SSF._table[14];
        cell.v = this.dateNum(cell.v);
      }
      else cell.t = 's';
      ws[cell_ref] = cell;
    }
  }

  /* TEST: proper range */
  if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range);
  return ws;
}

function Workbook() {
  if (!(this instanceof Workbook)) return new Workbook();
  this.SheetNames = [];
  this.Sheets = {};
}

function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}

// 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
function sheet2blob(sheet, sheetName) {
  sheetName = sheetName || 'sheet1';
  const workbook = {
    SheetNames: [sheetName],
    Sheets: {}
  };
  workbook.Sheets[sheetName] = sheet

  window.console.log(workbook)
  // 生成excel的配置项
  const wopts = {
    bookType: 'xlsx', // 要生成的文件类型
    bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
    type: 'binary'
  };

  const wbout = XLSX.write(workbook, wopts, { defaultCellStyle: defaultCellStyle });
  const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
  // 字符串转ArrayBuffer
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }
  return blob;
}

function addRangeBorder(range, ws) {
  let arr = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

  range.forEach(item => {
    let startColNumber = Number(item.s.r), endColNumber = Number(item.e.r);
    let startRowNumber = Number(item.s.c), endRowNumber = Number(item.e.c);
    const test = ws[arr[startRowNumber] + (startColNumber + 1)];
    for (let col = startColNumber; col <= endColNumber; col++) {
      for (let row = startRowNumber; row <= endRowNumber; row++) {
        //const test = ws[arr[j] + i];
        //console.log(arr[row] + (col + 1));
        ws[arr[row] + (col + 1)] = test;
        //{s:{border:{top:{style:'thin'}, left:{style:'thin'},bottom:{style:'thin'},right:{style:'thin'}}}};
      }
    }
  })
  return ws;
}

export function export_table_to_excel(id) {
  var theTable = document.getElementById(id);
  var oo = generateArray(theTable);
  var ranges = oo[1];

  /* original data */
  var data = oo[0];
  var ws_name = "SheetJS";

  var wb = new Workbook(),
    ws = sheet_from_array_of_arrays(data);

  /* add ranges to worksheet */
  // ws['!cols'] = ['apple', 'banan'];
  ws['!merges'] = ranges;

  /* add worksheet to workbook */
  wb.SheetNames.push(ws_name);
  wb.Sheets[ws_name] = ws;

  var wbout = XLSX.write(wb, {
    bookType: 'xlsx',
    bookSST: false,
    type: 'binary'
  });

  saveAs(new Blob([s2ab(wbout)], {
    type: "application/octet-stream"
  }), "test.xlsx")
}

export function export_json_to_excel({
  multiHeader = [],
  header,
  data,
  filename,
  merges = [],
  autoWidth = true,
  bookType = 'xlsx'
} = {}) {
  /* original data */
  filename = filename || 'excel-list'
  data = [...data]
  data.unshift(header);

  for (let i = multiHeader.length - 1; i > -1; i--) {
    data.unshift(multiHeader[i])
  }

  var ws_name = "SheetJS";
  var wb = new Workbook(),
    ws = sheet_from_array_of_arrays(data);

  if (merges.length > 0) {
    if (!ws['!merges']) ws['!merges'] = [];
    merges.forEach(item => {
      ws['!merges'].push(XLSX.utils.decode_range(item))
    })
  }

  if (autoWidth) {
    /*设置worksheet每列的最大宽度*/
    const colWidth = data.map(row => row.map(val => {
      /*先判断是否为null/undefined*/
      if (val == null) {
        return {
          'wch': 10
        };
      }
      /*再判断是否为中文*/
      else if (val.toString().charCodeAt(0) > 255) {
        return {
          'wch': val.toString().length * 2
        };
      } else {
        return {
          'wch': val.toString().length
        };
      }
    }))
    /*以第一行为初始值*/
    let result = colWidth[0];
    for (let i = 1; i < colWidth.length; i++) {
      for (let j = 0; j < colWidth[i].length; j++) {
        if (result[j]['wch'] < colWidth[i][j]['wch']) {
          result[j]['wch'] = colWidth[i][j]['wch'];
        }
      }
    }
    ws['!cols'] = result;
  }

  /* add worksheet to workbook */
  wb.SheetNames.push(ws_name);
  wb.Sheets[ws_name] = ws;

  var wbout = XLSX.write(wb, {
    bookType: bookType,
    bookSST: false,
    type: 'binary'
  });
  saveAs(new Blob([s2ab(wbout)], {
    type: "application/octet-stream"
  }), `${filename}.${bookType}`);
}

export function funtransformF(data, filename) {
  // 合并的数据处理
  let arr = [];
  // 合并的规则
  let mergeTitle = [];
  let count = 0;
  Object.values(data).forEach((list, idx) => {
    list.forEach((item, index) => {
      if (index === 0) {
        arr.push([
          `${item.id}`,
          `${item.desginCompName}`,
          `${item.submitAt}`,
          `${item.compName}`,
          `${item.fee}`,
          `${item.applyAmount}`,
          `${item.percent}`,
          `${item.approveAmount}`,
          `${item.is_in_brand_group}`,
          `${item.item}`,
        ])

      } else {
        // 要合并的字段用null代替
        arr.push([
          `${item.id}`,
          null,
          null,
          `${item.compName}`,
          `${item.fee}`,
          `${item.applyAmount}`,
          `${item.percent}`,
          `${item.approveAmount}`,
          `${item.is_in_brand_group}`,
          `${item.item}`,
        ])
      }
    })
    let a = list.length;
    count += a;
    mergeTitle = [...mergeTitle,
    {
      s: { r: count - (a - 1), c: 1 },
      e: {
        r: count, c: 1
      },
    },
    {
      s: { r: count - (a - 1), c: 2 },
      e: { r: count, c: 2 },
    },]

  })
  // console.log('mergeTitle', mergeTitle)

  let aoa = [
    [
      '申请编号', '设计公司', '入驻时间', '签约家具企业名称', '合同金额(万元)', '申请补贴(万元)', '申报比例', '审批金额(万元)', '是否加入南康家具集体商标(复核)', '指定代表产品实物'
    ],
    ...arr
  ]

  // json => sheet
  const sheet = sheet_from_array_of_arrays(aoa);

  //sheet['!merges'] = mergeTitle.concat(mergeContent);
  sheet['!merges'] = mergeTitle;
  // 冻结前6行和第一列，右下可以滑动
  sheet["!freeze"] = {
    xSplit: "1",
    ySplit: "6",
    topLeftCell: "B7",
    activePane: "bottomRight",
    state: "frozen",
  }
  sheet["!margins"] = { left: 1.0, right: 1.0, top: 1.0, bottom: 1.0, header: 0.5, footer: 0.5 }
  // 列宽 使用的不是像素值
  const sheetCols = [
    { wch: 40 }, // 申请编号
    { wch: 30 }, // 设计公司
    { wch: 20 }, //  入驻时间
    { wch: 39 }, // 签约家具企业名称
    { wch: 18 }, //  合同金额(万元)
    { wch: 18 }, // 申请补贴(万元)
    { wch: 15 }, // 申报比例
    { wch: 18 }, //  审批金额(万元)
    { wch: 12 }, // 是否加入南康家具集体商标(复核)
    { wch: 12 }, // 指定代表产品实物
  ];
  sheet['!cols'] = sheetCols;

  addRangeBorder(mergeTitle, sheet);

  const wbBlob = sheet2blob(sheet, '1')
  // 保存下载
  saveAs(wbBlob, `${filename}.xlsx`)
}
